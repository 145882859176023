import * as React from "react"
import {
  Container,
  Flex,
  FlexList,
  Space,
  VisuallyHidden,
  NavLink,
} from "../ui"
import {
  platformDesktopHeaderNavWrapper,
  mobileHeaderNavWrapper,
} from "../header.css.ts"
import { StaticImage } from "gatsby-plugin-image"
import { activeNavLink, movileActiveNavLink, subNavPosition } from "../../css/platform.css"
import { activeLink } from "../header.css"

export default function PlatformNavList(props) {

  const navItems = [{
    id: 1,
    href: "/platform",
    text: "Our Platform",
    description: "Our Platform",
    navItemType: '',
    isActive: props.navName === 'platform' ? true : false
  },
  {
    id: 2,
    href: "/bizlab",
    text: "Bizlab",
    description: "Bizlab",
    navItemType: '',
    isActive: props.navName === 'bizlab' ? true : false
  },
  {
    id: 3,
    href: "/subscriber",
    text: "Subscriber",
    description: "Subscriber",
    navItemType: '',
    isActive: props.navName === 'subscriber' ? true : false
  }]

  return (
    <header>
      <Container width="container" className={platformDesktopHeaderNavWrapper}>
        <Container  >
          <Space size={2} />
          <Flex variant="spaceBetween">
             <NavLink to="/">
              <VisuallyHidden>Home</VisuallyHidden>
              <StaticImage
                src="../images/logo.png"
                width="180"
                height="35"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="A Gatsby astronaut"
                style={{ marginBottom: `1.45rem` }}
              />
            </NavLink>
            <nav className={subNavPosition}>
              <FlexList gap={4}>
                {navItems &&
                  navItems.map((navItem) => (
                    <li key={navItem.id}>
                      <NavLink to={navItem.href} > {props.navName === navItem.text ? <span className={activeLink}>{navItem.text}</span> : navItem.text} </NavLink>
                    </li>
                  ))}
              </FlexList>
            </nav>
          </Flex>
        </Container>
      </Container>
      <Container className={mobileHeaderNavWrapper["closed"]}>
        <Space size={2} />
        <Flex variant="spaceBetween">
        <nav style={{ marginBottom: 10 }} right={3}>
          <FlexList gap={4}>
            {navItems &&
              navItems.map((navItem) => (
                <li key={navItem.id} >
                  <NavLink to={navItem.href} > {props.navName === navItem.text ? <span className={activeLink}>{navItem.text}</span> : navItem.text} </NavLink>
                </li>
              ))}
          </FlexList>
          </nav>
        </Flex>
      </Container>
    </header>
  )
}
