import * as React from "react"
import { Container, Box, PlatformSubhead, Section, Flex, PlatformHeading } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
import { about_hero, about_hero_img } from "../ui.css"

export default function PlatformHeaderTiles(props) {

    return (
        <>
        <Section className={about_hero}>
            <Container width="narrow2" >
                <Flex gap={4} variant="responsive">
                    <Box>
                        <PlatformHeading>
                            {props.header}
                        </PlatformHeading>
                        <PlatformSubhead>
                            The best platform for commerce skilling & job enhancement.
                        </PlatformSubhead>
                     
                    </Box>
                </Flex>
            </Container>
        </Section>
        <Section></Section>

            <Container className={about_hero_img} width="narrow2">
            {props.imageName === 'platform_banner' && 
                            <StaticImage src={`../../images/platform/platform_banner.png`} alt="bizLab_banner" />
                        }
                        {props.imageName === 'businessLaboratory' && 
                            <StaticImage src={`../../images/platform/businessLaboratory.png`} alt="bizLab_banner" />
                        }
                        {props.imageName === 'job_simulator' && 
                            <StaticImage src={`../../images/platform/job_simulator.png`} alt="bizLab_banner" />
                        }
                        {props.imageName === 'subscribers' && 
                            <StaticImage src={`../../images/platform/subscribers.png`} alt="bizLab_banner" />
                        }
            </Container>
      
        </>
    )
}
