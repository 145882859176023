import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PlatformNavList from '../components/platform/nav-items';
import { BsCheckCircleFill } from "react-icons/bs";
import PlatformHeaderTiles from "../components/platform/platform_header";
import {
  Box, Container, Flex, Heading, PlatformHeading, Text,
  PlatformSubhead, Section, PlatformPlainText, CardSection, Card,
  CardHeader, CardBody, FeatureCard
} from "../components/ui";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../components/ui.css.ts"
import { referralBonusPoints } from "../constants/platform";
import { marginBottom } from "../components/ui.css";

const SubscribersPage = () => (
  <Layout navName="Platform">
    <Seo title="Subscribers" />
    <PlatformNavList navName="Subscriber" />
    <PlatformHeaderTiles header="Subscribers" imageName="subscribers" />
    <Section>
      <Container width="tight" >
        <Flex gap={4} variant="responsive">
          <Box>
            <PlatformHeading style={{ color: '#081F32', fontWeight: '550', fontSize: '40px' }}>
              Who are <span style={{ color: '#4367EC' }}> Subscribers </span>
            </PlatformHeading>
            <PlatformSubhead style={{ color: '#374A59', fontFamily: 'Inter', fontStyle: 'normal', fontWeight: '450', fontSize: '15px' }}>
              Subscribers are the users of our Skill-Elevator platform. Any eligible candidates who create login, register in the platform and pay for the modules will be called as Subscribers.
            </PlatformSubhead>
          </Box>
        </Flex>
      </Container>
    </Section>
    <Section>
      <Container width="tight" >
        <Flex gap={4} variant="responsive">
          <Box>
            <StaticImage src='../images/platform/how_to_access_platform.png' alt="access_platform" width={550} />
            <Heading style={{ color: '#081F32', fontWeight: '550', fontSize: '40px' }}>
              Login Creation
            </Heading>
            <PlatformPlainText >
              The first step to access our platform is to create logn credentials. Please click below to create login. You need provide few details in the login screen and after successful submission, login credential will be shared to your email. Using those credentials you can login and view the platform.
            </PlatformPlainText>
            <StaticImage src='../images/platform/createLogin.png' alt="createLogin" width={150} />
          </Box>
          <Box>
            <StaticImage src='../images/loginCreation.png' alt="login_creation" width={750} />
          </Box>
        </Flex>
      </Container>
    </Section>
    <Section style={{ background: "radial-gradient(88.77% 227.16% at 12.81% -73.17%, #2B237C 0%, #251C72 31%, #0F054C 100%)", color: "#FFF", transform: "skewY(-5deg)" }}>
      <Container width="tight" style={{ transform: "skewY(5deg)" }}>
        <Flex gap={4} variant="responsive">
          <Box>
            <Heading style={{ color: '#FFFFFF', fontWeight: '550', fontSize: '40px' }}>
              <span style={{ color: '#00D4FF' }}>Registration </span> & Payment
            </Heading>
            <PlatformPlainText>
              The next step is to register in the platform. Registration process is very simple. Click on the registration menu in the platform and fill the details. Provide your name, address qualification etc. and upload your photo. After submission, it takes to the payment screen where you need to pay Registration fee and other module fee. Certain eligible student the registration fee is not applicable and even modules also will get at concessional price. Payment module shows all these details. One need to select the modules from the list and click for payment which takes to the 'Payment gateway'. We have different payment gateway options available such as paytm, googlepay, credi card, debit card, Net banking etc.
              Using one of the option student can pay. After successful payment student will get registration number and payment receipt to their official email ID.
            </PlatformPlainText>
          </Box>
          <Box>
            <StaticImage src='../images/platform/reg_and_payment.png' alt="reg_and_payment" width={900} />
          </Box>
        </Flex>
      </Container>
    </Section>
    <Section>
      <Container width="tight" >
        <Flex gap={4} variant="responsive">
          <Box>
            <PlatformHeading style={{ color: '#081F32', fontWeight: '550', fontSize: '40px' }}>
              <span style={{ color: '#4367EC' }}> How </span> Platform Works
            </PlatformHeading>
            <PlatformPlainText style={{
              color: '#374A59', fontFamily: 'Inter',
              fontStyle: 'normal', fontWeight: '450', fontSize: '15px'
            }}>
              We have two platforms available - viz (a) Business Laboratory  and (b) Job Simulator. One registration is enough to access two platforms. However, as process student would be allowed to access business labortatory at first and after completion of all modules, they will get the access to Job Simulator. In otherwords, system allows in sequential manner.
            </PlatformPlainText>
          </Box>
        </Flex>
      </Container>
    </Section>
    <Section>
      <Container>
        <Flex gap={4} variant="responsive">
          <Box>
            <StaticImage src='../images/platform/how_platform_works.png'
              alt="how_platform_works" width={1155} />
            <PlatformSubhead style={{
              color: '#374A59', fontFamily: 'Inter',
              fontStyle: 'normal', fontWeight: '450', fontSize: '15px'
            }}>
              When student login in to the platform, it takes to Business laboratory platform which has the following menus available at the left panel
            </PlatformSubhead>
          </Box>
        </Flex>
      </Container>
    </Section>
    <CardSection>
      <Container width="fullbleed_margin">
        <Flex gap={1} variant="responsive">
          <Box>
            <Card>
              <CardHeader>
                1. Dashboard
              </CardHeader>
              <CardBody >
                Any commerce faculty or commerce HOD or Principal of a college can act as Mentor for Skill Development.
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                2. Modules
              </CardHeader>
              <CardBody >
                There twenty (20) Modules available in the Business Laboratory platoform. Details are mentioned in the next section. Student need to start working from module in sequential manner. In otherwords after completion of first module only, system allows to the next module. Accessing each module is very simple. Just pay the money and click the start/resume button. It takes to the module.
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                3. Registration
              </CardHeader>
              <CardBody >
                Procedure for registraion has been described in this menu.
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                4. Payments
              </CardHeader>
              <CardBody >
                Procedure for payment of registration and module fee has been enabled in this menu.
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardHeader>
                5. Wallet
              </CardHeader>
              <CardBody >
                After making payments, the amounts will be shown in the wallet which will then be utilized for purchase of modules. This menu will show the details and balance in the wallet account.
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                6. Referral
              </CardHeader>
              <CardBody >
                Using this menu, student can refer the platform to their friends and network. Subscribers need to enter Name, Mobile and email of the referred person. Module link
                would be sent to their email or mobile. Using this link referred candidates can regiser and pay for the modules. Upon successful registraion of the referred candidiates, the
                subscriber will get referral bonus which will be displayed in the wallet account.
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                7. Profile
              </CardHeader>
              <CardBody >
                This gives the complete particulars about the subscribers.
              </CardBody>
            </Card>
          </Box>
        </Flex>
      </Container>
    </CardSection>
    <Section style={{ background: "radial-gradient(88.77% 227.16% at 12.81% -73.17%, #2B237C 0%, #251C72 31%, #0F054C 100%)", color: "#FFF", transform: "skewY(-5deg)" }}>
      <Container width="tight" style={{ transform: "skewY(5deg)", color: 'white' }}>
        <Flex gap={4} variant="responsive">
          <Box >
            <Heading style={{ color: '#00D4FF', fontWeight: '550', fontSize: '40px' }}>
              Features
            </Heading>
            <Heading style={{ color: '#FFFFFF', fontWeight: '550', fontSize: '40px' }}>
              for subscriibers
            </Heading>
            <FeatureCard>
              <StaticImage src='../images/platform/selfLearn.png'
                alt="selfLearn" />
              <CardHeader className={styles.paddingAllSide}>
                Self Learn
              </CardHeader>
              <Text >
                Each module has been designed keeping in view of easy learning or self learing in mind. In otherwords, subscriber does not requires any bodies training or support.
              </Text>
            </FeatureCard>
            <FeatureCard>
              <StaticImage src='../images/platform/module.png'
                alt="module" />
              <CardHeader>
                Module completion and Scoring
              </CardHeader>
              <Text >
                {` Upon completion of particular module, platform gives score and time taken to complete the module. Score is mentioned in % which starts from 1 to 100%. 
                
          
                ` }
              </Text>
            </FeatureCard>
          </Box>
          <Box>
            <FeatureCard>
              <CardHeader style={{ marginTop: '25%' }}>
              </CardHeader>
              <Text >
                Each module has theory and video demo. Using these options student can start using modules easily. However, subscriber has option to seek helpdesk from BizzLab experts by sending email or calling them.
              </Text>
            </FeatureCard>
            <FeatureCard>
              <StaticImage src='../images/platform/certificate.png'
                alt="certificate" />
              <CardHeader>
                Certificate Download
              </CardHeader>
              <Text >
                Susbcribers can also dowload certificate of completion of Skill training and experience.
              </Text>
            </FeatureCard>
          </Box>
        </Flex>
      </Container>
    </Section>
    <Section >
      <Container width="tight" >
        <Flex gap={4} variant="responsive">
          <Box>
            <Heading style={{ color: '#4367EC', fontWeight: '600', fontSize: '27px' }}>
              Referral Bonus to Subscriber
            </Heading>
            <PlatformSubhead style={{ color: '#2D0E4A', 
            fontFamily: 'Arial', fontStyle: 'normal', fontWeight: '550', fontSize: '15px', marginBottom: '25px' }}>
              <StaticImage src="../images/skill_elevator_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} 
              alt="skill_elevator_icon" width={20} /> EARN WHILE YOU REFER TO YOUR FRIENDS
            </PlatformSubhead>
            <Box>
              {
                referralBonusPoints && referralBonusPoints.map(point => {
                  return (
                    <PlatformPlainText >
                      <span> <StaticImage src='../images/platform/point.png' width={20} alt="login_creation" /> </span>
                      <span>{point} </span>
                    </PlatformPlainText>
                  )
                })
              }
            </Box>
          </Box>
          <Box>
            <Text>
            <StaticImage src='../images/platform/referal_for_subscribers.png' alt="login_creation" width={300} className={marginBottom}/>
            </Text>
          </Box>
        </Flex>
      </Container>
    </Section>
    
  </Layout>
)

export default SubscribersPage
